var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'RateTable';
let RateTable = class RateTable {
    id;
    //todo: convert to id fields for FKs
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    rateName;
    description;
    label;
    label2;
    label3;
    table_type;
    rate_type;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTable.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By' /*fieldType: 'User'*/ })
], RateTable.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], RateTable.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By' /*fieldType: 'User'*/ })
], RateTable.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], RateTable.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Rate Name', fieldType: 'string' })
], RateTable.prototype, "rateName", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Description', fieldType: 'string' })
], RateTable.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Label', fieldType: 'string' })
], RateTable.prototype, "label", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Label 2', fieldType: 'string' })
], RateTable.prototype, "label2", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Label 3', fieldType: 'string' })
], RateTable.prototype, "label3", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Table Type', fieldType: 'string' })
], RateTable.prototype, "table_type", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Rate Type', fieldType: 'string' })
], RateTable.prototype, "rate_type", void 0);
RateTable = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Rate Table',
        displayNamePlural: 'Rate Tables',
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        usesNewDataModel: true,
        trailMapTypes: [TrailMapType.HomePage],
        supports: {
            views: true,
        },
    })
], RateTable);
export { RateTable };
