var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { TrailMapType } from '../../trail-maps/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
export var ViewType;
(function (ViewType) {
    ViewType["Detail"] = "detail";
    ViewType["Summary"] = "summary";
    ViewType["Sql"] = "sql";
})(ViewType || (ViewType = {}));
const recordTypeId = 'View';
let View = class View {
    id;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    friendlyName;
    recordType;
    isShared;
    viewType;
};
__decorate([
    FieldData({ recordTypeId, label: 'View ID', fieldType: 'uuid' })
], View.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], View.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], View.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], View.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], View.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], View.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], View.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Name' })
], View.prototype, "friendlyName", void 0);
__decorate([
    FieldData({ recordTypeId, formatting: 'recordtype' })
], View.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], View.prototype, "isShared", void 0);
__decorate([
    FieldData({
        recordTypeId,
        codeEnum: ViewType,
        label: 'View Type',
    })
], View.prototype, "viewType", void 0);
View = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'View',
        displayNamePlural: 'Views',
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        supports: {
            views: true,
        },
        trailMapTypes: [TrailMapType.HomePage],
        lookupProperties: { resultProperties: ['friendlyName'] },
    })
], View);
export { View };
