var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { ClaimTransactionCategoryEnum } from './claims/claim-transactions';
const recordTypeId = 'Code';
let Code = class Code {
    id;
    code;
    description;
    isSystemType;
    longCode;
    effectiveDate;
    expirationDate;
    isInactive;
    codeSetId;
    codeSet;
    alternateCodes;
    codeWithDescription;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
    sequence;
    //#region Code Set Specific Properties
    /**
     * Specific to Incident Type Code Set Id.  We don't set codeSet or fieldType because it messes with coverage lists
     */
    coverage;
    transactionBucket;
    transactionCategory;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Code.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], Code.prototype, "code", void 0);
__decorate([
    FieldData({ recordTypeId })
], Code.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Code.prototype, "isSystemType", void 0);
__decorate([
    FieldData({ recordTypeId })
], Code.prototype, "longCode", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Code.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Code.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Code.prototype, "isInactive", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'CodeSet' })
], Code.prototype, "codeSetId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'CodeSet' })
], Code.prototype, "codeSet", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableField: true })
], Code.prototype, "alternateCodes", void 0);
__decorate([
    FieldData({ recordTypeId, virtualDbField: true })
], Code.prototype, "codeWithDescription", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Code.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], Code.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Code.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Code.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], Code.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Code.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Code.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], Code.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Code.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], Code.prototype, "sequence", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'codelist', codeSet: 'coverages' })
], Code.prototype, "coverage", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true, fieldType: 'codelist', codeList: [], label: 'Bucket' })
], Code.prototype, "transactionBucket", void 0);
__decorate([
    FieldData({
        recordTypeId, jsonStorage: true, fieldType: 'codelist', label: 'Category', codeList: [
            { code: ClaimTransactionCategoryEnum.Payment, description: 'Payment' },
            { code: ClaimTransactionCategoryEnum.Reserve, description: 'Reserve' },
            { code: ClaimTransactionCategoryEnum.Recovery, description: 'Recovery' },
        ]
    })
], Code.prototype, "transactionCategory", void 0);
Code = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Code',
        displayNamePlural: 'Codes',
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'description',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'isSystemType',
                filterType: FilterType.Equals,
            },
        ],
    })
], Code);
export { Code };
