export const claimAdminSettingFilter = {
    category: 'Claim',
    name: 'adminSettings',
};
export const DefaultFinancialBucketLabels = {
    bucket1: 'Med/Comp/BI',
    bucket2: 'Expense',
    bucket3: 'Ind/Coll/PD',
    bucket4: 'Deductible',
    bucket5: 'Subrogation',
    bucket6: 'Legal Expense',
    bucket7: 'Other'
};
