import { Injectable, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ComponentStore } from '@ngrx/component-store';
import { IsLoadingService } from '@service-work/is-loading';
import { Code } from 'portal-commons/dist/data-model/record-types/code';
import { CodeSet } from 'portal-commons/dist/data-model/record-types/code-set';
import { CodePermissions, RoleCategories } from 'portal-commons/dist/roleEnums';
import {
  catchError,
  combineLatest,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { ToastNotificationService } from '../notifications/toasts/toast-notification.service';
import { CodesApiService } from './codes-api.service';
import { ErrorMessagePipe } from 'app/shared/pipes/error-message.pipe';

export interface CodesState {
  codeSets?: CodeSet[];
  codeMaps: Map<string, Code[]>;
  codeSetsLoaded: boolean;
  activeCodeSet?: CodeSet;
  activeCode?: Code;
  canAdd: boolean;
  canDelete: boolean;
  canEdit: boolean;
}

const DEFAULT_STATE: CodesState = {
  codeSetsLoaded: false,
  canAdd: false,
  canDelete: false,
  canEdit: false,
  codeMaps: new Map<string, Code[]>(),
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CodesStoreService extends ComponentStore<CodesState> {
  public readonly LOADING_CODE_SETS = 'LOADING_CODE_SETS';
  public readonly SAVE_CODE_SET = 'SAVE_CODE_SET';
  public readonly DELETE_CODE_SET = 'DELETE_CODE_SET';
  public readonly LOADING_CODES = 'LOADING_CODES';
  public readonly SAVE_CODE = 'SAVE_CODE';
  public readonly DELETE_CODE = 'DELETE_CODE';

  errorPipe = inject(ErrorMessagePipe);

  constructor(
    private codesApi: CodesApiService,
    private loadingService: IsLoadingService,
    private notificationService: ToastNotificationService,
    private authService: AuthService,
  ) {
    super(DEFAULT_STATE);
  }

  readonly activeCodeSet$ = this.select(({ activeCodeSet }) => activeCodeSet);

  readonly activeCode$ = this.select(({ activeCode }) => activeCode);

  readonly codeSetsLoaded$ = this.select(({ codeSetsLoaded }) => codeSetsLoaded);

  readonly codeSets$ = this.select(({ codeSets }) => codeSets);

  readonly codeMaps$ = this.select(({ codeMaps }) => codeMaps);

  readonly allowAdd$ = this.select(({ canAdd }) => canAdd);

  readonly activeCodeSetIsSystem$ = this.select(this.activeCodeSet$, (activeSet) => {
    if (!activeSet) {
      return false;
    }
    return activeSet.isSystemType ?? false;
  });

  readonly activeCodeIsSystem$ = this.select(this.activeCode$, (activeCode) => {
    if (!activeCode) {
      return false;
    }
    return activeCode.isSystemType ?? false;
  });

  readonly activeCodeSetAllowAdditions$ = this.select(this.activeCodeSet$, (activeSet) => {
    if (!activeSet) {
      return false;
    }
    return activeSet.systemConfig?.allowAdditions ?? false;
  });

  readonly activeCodes$ = combineLatest([
    this.activeCodeSet$.pipe(filter((f) => !!f)),
    this.codeMaps$,
  ]).pipe(
    map(([codeSet, maps]) => {
      if (!codeSet) {
        return undefined;
      }
      if (!maps.has(codeSet.id)) {
        return undefined;
      }
      return maps.get(codeSet.id);
    }),
    shareReplay(1),
  );

  readonly activeCodeSetIsNew$ = this.select(this.activeCodeSet$, (activeSet) => {
    if (!activeSet) {
      return false;
    }
    return activeSet.id === '';
  });

  readonly activeCodeIsNew$ = this.select(this.activeCode$, (activeCode) => {
    if (!activeCode) {
      return false;
    }
    return activeCode.id === '';
  });

  readonly allowAddCodeToSet$ = this.select(
    this.activeCodeSetIsSystem$,
    this.select(({ canAdd }) => canAdd),
    this.activeCodeSetAllowAdditions$,
    (isSystem, canAdd, systemAllowNew) => {
      if (!isSystem && canAdd) {
        return true;
      }
      if (isSystem && canAdd && systemAllowNew) {
        return true;
      }
      return false;
    },
  );

  readonly allowModifyCode$ = this.select(
    this.activeCodeIsNew$,
    this.activeCodeIsSystem$,
    this.activeCodeSetIsSystem$,
    this.select(({ canAdd }) => canAdd),
    this.select(({ canEdit }) => canEdit),
    this.allowAddCodeToSet$,
    (isNew, isSystemCode, isSystemCodeSet, canAdd, canEdit, allowAdditions) => {
      if (isSystemCode) {
        return false;
      }
      if (isSystemCodeSet && isNew && allowAdditions) {
        return true;
      }
      if (isSystemCodeSet && !isSystemCode && !isNew && canEdit) {
        return true;
      }
      if (isNew && canAdd) {
        return true;
      }
      if (!isNew && canEdit) {
        return true;
      }
      return false;
    },
  );

  readonly allowModify$ = this.select(
    this.activeCodeSetIsNew$,
    this.activeCodeSetIsSystem$,
    this.select(({ canAdd }) => canAdd),
    this.select(({ canEdit }) => canEdit),
    this.allowAddCodeToSet$,
    (isNew, isSystem, canAdd, canEdit, allowAdditions) => {
      if (isSystem && allowAdditions) {
        return true;
      }
      if (isSystem) {
        return false;
      }
      if (isNew && canAdd) {
        return true;
      }
      if (!isNew && canEdit) {
        return true;
      }
      return false;
    },
  );

  readonly allowDelete$ = this.select(
    this.activeCodeSetIsNew$,
    this.activeCodeSetIsSystem$,
    this.select(({ canDelete }) => canDelete),
    (isNew, isSystem, canDelete) => {
      if (isSystem || isNew) {
        return false;
      }
      if (!isNew && canDelete) {
        return true;
      }
      return false;
    },
  );

  readonly allowDeleteCode$ = this.select(
    this.activeCodeIsSystem$,
    this.select(({ canDelete }) => canDelete),
    (isSystem, canDelete) => {
      if (isSystem) {
        return false;
      }
      if (canDelete) {
        return true;
      }
      return false;
    },
  );

  readonly getCodeSets$ = this.codeSets$.pipe(
    switchMap((sets) => {
      if (sets) {
        return of(sets);
      }
      return this.codesApi.getCodeSets().pipe(shareReplay(1));
    }),
    tap((results) => {
      this.patchState({
        codeSets: results,
        codeSetsLoaded: true,
      });
    }),
  );

  private codeSetSaveEvent = new Subject<CodeSet>();
  readonly codeSetSaveEvent$ = this.codeSetSaveEvent.asObservable();

  private codeSetDeleteEvent = new Subject<CodeSet>();
  readonly codeSetDeleteEvent$ = this.codeSetDeleteEvent.asObservable();

  private codeSaveEvent = new Subject<Code>();
  readonly codeSaveEvent$ = this.codeSaveEvent.asObservable();

  private codeDeleteEvent = new Subject<Code>();
  readonly codeDeleteEvent$ = this.codeDeleteEvent.asObservable();

  private codeSetErrorEvent = new Subject<{ title: string; error: Error }>();
  readonly codeSetErrorEvent$ = this.codeSetErrorEvent.asObservable();

  private codeErrorEvent = new Subject<{ title: string; error: Error }>();
  readonly codeErrorEvent$ = this.codeErrorEvent.asObservable();

  initCodeStore() {
    this.authService
      .hasPermission$(RoleCategories.Codes, CodePermissions.AddCodeSets)
      .pipe(
        untilDestroyed(this),
        tap((permVal) => {
          this.patchState({ canAdd: permVal });
        }),
      )
      .subscribe();
    this.authService
      .hasPermission$(RoleCategories.Codes, CodePermissions.EditCodeSets)
      .pipe(
        untilDestroyed(this),
        tap((permVal) => {
          this.patchState({ canEdit: permVal });
        }),
      )
      .subscribe();
    this.authService
      .hasPermission$(RoleCategories.Codes, CodePermissions.DeleteCodeSets)
      .pipe(
        untilDestroyed(this),
        tap((permVal) => {
          this.patchState({ canDelete: permVal });
        }),
      )
      .subscribe();
    this.loadCodeSets();

    this.codeErrorEvent$
      .pipe(
        untilDestroyed(this),
        tap((event) => {
          this.notificationService.error(this.errorPipe.transform(event.error), event.title);
        }),
      )
      .subscribe();
  }

  readonly loadCodeSets = this.effect((trigger$) => {
    return trigger$.pipe(
      switchMap(() => {
        if (this.get().codeSetsLoaded) {
          return of(null);
        }
        return this.loadingService.add(
          this.codesApi.getCodeSets().pipe(
            tap((codeSets) => {
              if (codeSets) {
                this.patchState({
                  codeSets: codeSets,
                  codeSetsLoaded: true,
                });
              }
            }),
            catchError((error) => {
              console.error('unable to load code sets', error);
              return of(null);
            }),
            shareReplay(1),
          ),
          { key: this.LOADING_CODE_SETS },
        );
      }),
    );
  });

  readonly setCurrentCodeSet = this.effect((trigger$: Observable<string>) => {
    return trigger$.pipe(
      switchMap((codeSetId) => {
        return combineLatest([of(codeSetId), this.codeSets$.pipe(filter((f) => !!f))]);
      }),
      tap(([id, codeSets]) => {
        console.log('calling setCurrentCodeSet', id);
        if (id === 'new') {
          this.patchState({
            activeCodeSet: {
              id: '',
              description: '',
            },
          });
        } else {
          if (this.get().activeCodeSet?.id !== id) {
            this.patchState({
              activeCodeSet: codeSets?.find((f) => f.id === id),
            });
            if (!this.get().codeMaps.has(id)) {
              this.setCurrentCodes(id);
            }
          }
        }
      }),
    );
  });

  readonly setCurrentCodes = this.effect((codeSetId$: Observable<string>) => {
    return codeSetId$.pipe(
      switchMap((codeSetId) => {
        const maps = this.get().codeMaps;
        if (maps.has(codeSetId)) {
          return of({ codeSetId: codeSetId, codes: maps.get(codeSetId) });
        }
        return this.codesApi.getCodes(codeSetId).pipe(
          map((codes) => {
            return { codeSetId: codeSetId, codes: codes };
          }),
          shareReplay(1),
        );
      }),
      tap((result: { codeSetId: string; codes: Code[] | undefined }) => {
        const newMaps = new Map(this.get().codeMaps);
        newMaps.set(result.codeSetId, result.codes ?? []);
        this.patchState({
          codeMaps: newMaps,
        });
      }),
    );
  });

  readonly setCurrentCode = this.effect((codeId$: Observable<string>) => {
    return codeId$.pipe(
      switchMap((codeId) => {
        return combineLatest([
          of(codeId),
          this.activeCodeSet$.pipe(filter((f) => !!f)),
          this.activeCodes$.pipe(filter((f) => !!f)),
        ]);
      }),
      tap(([id, codeSet, codes]) => {
        if (id === 'new') {
          this.patchState({
            activeCode: {
              id: '',
              codeSetId: codeSet!.id,
              code: '',
              description: '',
            },
          });
        } else {
          if (codes?.find((f) => f.id === id)) {
            this.patchState({
              activeCode: codes?.find((f) => f.id === id),
            });
          }
        }
      }),
    );
  });

  //#region Code Set Effects
  readonly deleteCodeSet = this.effect((codeSet$: Observable<CodeSet>) => {
    return codeSet$.pipe(
      switchMap((codeSet) => {
        if (codeSet.isSystemType) {
          this.notificationService.error('System Code Sets cannot be deleted');
          return of(null);
        }

        return this.loadingService
          .add(this.codesApi.deleteCodeSet(codeSet), {
            key: this.DELETE_CODE_SET,
          })
          .pipe(
            catchError((error) => {
              console.error('Delete failed', error);
              this.notificationService.error('Unable to delete code set');
              return of(null);
            }),
          );
      }),
      tap((removedCodeSet: CodeSet | null) => {
        if (removedCodeSet === null) {
          return;
        }
        this.removeCodeSetFromStore(removedCodeSet.id);
        this.notificationService.success('Deleted successfully');
        this.codeSetDeleteEvent.next(removedCodeSet);
      }),
    );
  });

  readonly removeCodeSetFromStore = this.updater((state, id: string) => {
    const newSets = [...(state.codeSets?.filter((f) => f.id !== id) ?? [])];
    return {
      ...state,
      codeSets: newSets,
      activeCodeSet: state.activeCodeSet?.id === id ? undefined : state.activeCodeSet,
    };
  });

  readonly saveCodeSet = this.effect((codeSet$: Observable<Partial<CodeSet>>) =>
    codeSet$.pipe(
      switchMap((codeSet) => {
        return this.loadingService
          .add(
            this.codesApi.saveCodeSet(codeSet).pipe(
              catchError((error) => {
                this.codeSetErrorEvent.next({
                  title: 'Unable to Save Code Set',
                  error: error as Error,
                });
                return of(null);
              }),
              shareReplay(1),
            ),
            {
              key: this.SAVE_CODE_SET,
            },
          )
          .pipe(
            catchError((error) => {
              this.codeSetErrorEvent.next({
                title: 'Unable to Save Code Set',
                error: error as Error,
              });
              return of(null);
            }),
          );
      }),
      tap((savedRec: CodeSet | null) => {
        console.log('codeSetSaved', savedRec);
        if (!savedRec) {
          return;
        }
        this.upsertCodeSet(savedRec);
        this.notificationService.success('Code Set added successfully');
        this.codeSetSaveEvent.next(savedRec);
      }),
    ),
  );

  readonly upsertCodeSet = this.updater((state, codeSet: CodeSet) => {
    const sets = [...(state.codeSets ?? [])];
    const match = sets.findIndex((f) => f.id === codeSet.id);
    if (match > -1) {
      sets[match] = codeSet;
    } else {
      sets.push(codeSet);
    }

    return {
      ...state,
      codeSets: sets,
      activeCodeSet: codeSet,
    };
  });

  //#endregion

  //#region Code Effects
  readonly deleteCode = this.effect((code$: Observable<Code>) => {
    return code$.pipe(
      withLatestFrom(this.activeCodeSet$),
      switchMap(([code, codeSet]) => {
        return this.loadingService
          .add(this.codesApi.deleteCode(codeSet!.id, code), {
            key: this.DELETE_CODE,
          })
          .pipe(
            catchError((error) => {
              console.error('Delete failed', error);
              this.notificationService.error('Unable to delete code');
              return of(null);
            }),
          );
      }),
      tap((removedCode: Code | null) => {
        if (removedCode === null) {
          return;
        }
        this.removeCodeFromStore({
          codeSetId: removedCode.codeSetId as string,
          id: removedCode.id,
        });
        this.notificationService.success('Deleted successfully');
        this.codeDeleteEvent.next(removedCode);
      }),
    );
  });

  readonly removeCodeFromStore = this.updater((state, item: { codeSetId: string; id: string }) => {
    if (!state.codeMaps.has(item.codeSetId)) {
      return { ...state };
    }
    const newCodes = [
      ...(state.codeMaps.get(item.codeSetId)!.filter((f) => f.id !== item.id) ?? []),
    ];
    const newMaps = new Map(state.codeMaps);
    newMaps.set(item.codeSetId, newCodes);
    return {
      ...state,
      codeMaps: newMaps,
      activeCode: state.activeCode?.id === item.id ? undefined : state.activeCode,
    };
  });

  readonly saveCode = this.effect((code$: Observable<Partial<Code>>) =>
    code$.pipe(
      withLatestFrom(this.activeCodeSet$),
      switchMap(([code, codeSet]) => {
        return this.loadingService
          .add(
            this.codesApi.saveCode(codeSet!.id!, code).pipe(
              catchError((error) => {
                this.codeErrorEvent.next({
                  title: 'Unable to Save Code',
                  error: error as Error,
                });
                return of(null);
              }),
              shareReplay(1),
            ),
            {
              key: this.SAVE_CODE,
            },
          )
          .pipe(
            catchError((error) => {
              this.codeErrorEvent.next({
                title: 'Unable to Save Code',
                error: error as Error,
              });
              return of(null);
            }),
          );
      }),
      tap((savedRec: Code | null) => {
        console.log('codeSaved', savedRec);
        if (!savedRec) {
          return;
        }
        this.upsertCode(savedRec);
        this.notificationService.success('Code added successfully');
        this.codeSaveEvent.next(savedRec);
      }),
    ),
  );

  readonly upsertCode = this.updater((state, code: Code) => {
    const codeSetId = code.codeSetId as string;
    const newMaps = new Map(state.codeMaps);
    const newCodes = !newMaps.has(codeSetId) ? [] : [...newMaps.get(codeSetId)!];
    const match = newCodes.findIndex((f) => f.id === code.id);
    if (match > -1) {
      newCodes[match] = code;
    } else {
      newCodes.push(code);
    }
    newMaps.set(codeSetId, newCodes);

    return {
      ...state,
      codeMaps: newMaps,
      activeCode: code,
    };
  });

  //#endregion
}
