import {
  BasicFieldTypes,
  DataModelRecordTypeField,
} from 'portal-commons/dist/data-model/record-types';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { format, parseISO } from 'date-fns';

import { TrailMapConfigService } from '../trail-maps/trail-map-config.service';
import { getCodeListDisplayValue } from '../utils/codelist-helper';
import { getEnumDisplayValue } from '../utils/enum-helper';
import { toNumber } from '../utils/number-helper';
import { isValidRelativeDateString } from 'portal-commons/dist/relative-dates/models';
import { getRelativeDateString } from 'portal-commons/dist/relative-dates/relative-date-helper';
import { DataModelStoreService } from '../data-model/services/data-model.store';

@Injectable({
  providedIn: 'root',
})
export class DataFormatService {
  tmConfig = inject(TrailMapConfigService);
  dataModelStore = inject(DataModelStoreService);
  constructor(
    private datePipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
  ) { }

  formatTime(militaryTime: string) {
    try {
      if (!!!militaryTime) {
        return militaryTime;
      }
      const hours = +militaryTime.split(':')[0];
      const minutes = +militaryTime.split(':')[1];
      return `${(hours > 12) ? hours - 12 : hours}:${minutes} ${(hours >= 12) ? 'PM' : 'AM'}`;
    }
    catch (err) {
      console.error(`Unable to format time: ${militaryTime}`, err);
      return militaryTime;
    }
  }

  formatDate(dateString: string | Date | number | null | undefined, format = 'shortDate') {
    if (typeof dateString === 'string' && isValidRelativeDateString(dateString)) {
      dateString = getRelativeDateString(dateString);
    }
    return this.datePipe.transform(dateString, format);
  }

  getDateOnlyString(input: Date | null | undefined) {
    if (!!input) {
      return input.toISOString().split('T')[0];
    }
    return null;
  }

  parseToDateString(input: string | null | undefined) {
    return this.getDateOnlyString(this.parseDate(input));
  }

  getDatePickerValueFromShortDateString(input: string | undefined) {
    if (!!input) {
      return parseISO(input).toISOString();
    }
    return '';
  }

  getLocalDateStringFromIsoString(input: string | undefined) {
    if (!!input) {
      return format(parseISO(input), 'yyyy-MM-dd');
    }
    return '';
  }

  getLocalIsoDateStringForInput(input: Date | string | number | null | undefined) {
    const dateVal = this.parseDate(input);
    return dateVal?.toISOString();
  }

  parseDate(input: Date | string | number | null | undefined): Date | null {
    try {
      if (input instanceof Date) {
        return new Date(input.toDateString());
      }
      if (input === null || input === undefined) {
        return null;
      }
      if (typeof input === 'number') {
        return new Date(input);
      }
      return parseISO(input.split('T')[0]);
    } catch (err: any) {
      console.error(`Unable to getDateFromString ${input}`, err);
      throw null;
    }
  }

  formatPercentage(input: number, format: string) {
    if (format.toLowerCase() === 'percentage-whole-number') {
      input = input / 100.0;
    }
    return this.percentPipe.transform(input, '1.0-4');
  }

  formatCurrency(numberInput: number, numDigits = 2) {
    return this.currencyPipe.transform(
      toNumber(numberInput),
      undefined,
      undefined,
      `1.${numDigits}-${numDigits}`,
    );
  }

  specialFieldFormatTags(field: DataModelRecordTypeField) {
    if (field.codeEnum) {
      return 'codeenum';
    }
    if (field.codeList) {
      return 'codelist';
    }

    return undefined;
  }

  getFormattingForField(field: DataModelRecordTypeField | undefined) {
    if (!field || !field.fieldType) {
      return undefined;
    }

    if (this.specialFieldFormatTags(field)) {
      return this.specialFieldFormatTags(field);
    }

    if (field.fieldType === 'mapArea') { return field.fieldType; }
    if (field.fieldType === 'recordTypeLookup') { return field.fieldType; }

    const asIs: BasicFieldTypes[] = [
      'date',
      'currency',
      'integer',
      'time',
      'boolean',
      'percentage',
      'percentage-whole-number',
    ];
    if (asIs.find((f) => f === field.fieldType)) {
      return field.fieldType;
    }

    switch (field.fieldType.toLowerCase()) {
      case 'datetime':
        return 'DATE:short';
      case 'decimal':
        return `number:${field.formatting ?? '2'}`;
      default:
        return field.formatting;
    }
  }

  readonly numericFormats = [
    'integer',
    'decimal',
    'currency',
    'percentage',
    'percentage-whole-number',
  ];
  isNumericFormat(format?: string) {
    if (!format) {
      return false;
    }
    return (
      this.numericFormats.includes(format.toLowerCase()) ||
      format.toLowerCase().startsWith('number:') ||
      format.toLowerCase().startsWith('currency:')
    );
  }

  formatFieldValue(input: any, field: DataModelRecordTypeField) {
    const formatting = this.getFormattingForField(field);
    if (!formatting) {
      return input;
    }

    if (formatting.toLowerCase() === 'maparea') {
      return this.tmConfig.formatMapArea(input);
    }

    if (formatting.toLowerCase() === 'recordtypelookup') {
      return this.dataModelStore.getRecordType(input)?.displayNameSingular ?? input;
    }

    if (field.codeEnum) {
      return getEnumDisplayValue(field.codeEnum, input);
    }

    if (field.codeList) {
      return getCodeListDisplayValue(field.codeList, input);
    }

    return this.formatValue(input, formatting);
  }

  formatValue(input: any, formatting?: string) {
    try {
      if (!formatting) {
        return input;
      }

      if (formatting.toLowerCase() === 'integer') {
        if (input === undefined || input === null) {
          return input;
        }
        return (!isNaN(input) ? input.toFixed(0) : input.toString()).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ',',
        );
      }

      if (formatting === 'time') {
        return this.formatTime(input);
      }

      if (formatting.toLowerCase().startsWith('number:')) {
        if (!isNaN(formatting.substring(7) as any)) {
          const precision = +formatting.substring(7);
          return !isNaN(input) ? input.toFixed(precision) : input.toString();
        }
      }

      if (formatting.toLowerCase().startsWith('percentage')) {
        return this.formatPercentage(input, formatting);
      }

      if (formatting.toLowerCase() === 'boolean') {
        // console.log('boolean input', input, typeof input);
        if (typeof input === 'boolean') {
          return input === true ? 'Yes' : 'No';
        }
        if (typeof input === 'string') {
          return input.toLowerCase() === 'true' || input.toLowerCase() === 'yes' ? 'Yes' : 'No';
        }
      }

      if (formatting.toLowerCase() === 'uppercase') {
        return input.toUpperCase();
      }

      if (formatting.toLowerCase() === 'date') {
        return this.formatDate(input);
      }

      if (formatting.toLowerCase() === 'currency') {
        return this.formatCurrency(input);
      }

      if (formatting.toLowerCase().startsWith('currency:')) {
        const decimals = formatting.substring(9);
        return this.formatCurrency(input, parseInt(decimals));
      }

      if (formatting.toLowerCase().startsWith('date:')) {
        const dateFormat = formatting.substring(5);
        return this.formatDate(input, dateFormat);
      }

      if (formatting.toLowerCase().startsWith('ellipsis:')) {
        const length = parseInt(formatting.substring(9));
        return input.length <= length ? input : `${input.substring(0, length - 1)}...`;
      }
    } catch (error) {
      console.log(`Error formatting input`, input, formatting, error);
    }
    return input;
  }
}
