import {
  DataModelRecordType,
  DataModelRecordTypeField,
} from 'portal-commons/dist/data-model/record-types';
import {
  DataModelRefreshTypeEnum,
  WsRefreshDataModelMessage,
} from 'portal-commons/dist/data-model/ws-models';
import { Injectable, inject } from '@angular/core';
import { Observable, catchError, filter, map, of, shareReplay, tap, withLatestFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CodesApiService } from 'app/core/codes/codes-api.service';
import { ComponentStore } from '@ngrx/component-store';
import { DataModelApiService } from './data-model-api.service';
import { WebsocketService } from 'app/core/auth/web-socket.service';
import { WsMessageTypes } from 'portal-commons/dist/ws/model';

export interface DataModelState {
  recordTypesLoaded: boolean;
  recordTypes: Map<string, DataModelRecordType> | undefined;
  recordTypeAliases: Map<string, string> | undefined;
}

const DEFAULT_STATE: DataModelState = {
  recordTypesLoaded: false,
  recordTypes: undefined,
  recordTypeAliases: undefined,
};

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class DataModelStoreService extends ComponentStore<DataModelState> {
  codesApi = inject(CodesApiService);
  ws = inject(WebsocketService);

  constructor(private dataModelApiService: DataModelApiService) {
    super(DEFAULT_STATE);

    this.ws.incomingMessages$
      .pipe(
        untilDestroyed(this),
        filter((f: WsRefreshDataModelMessage) => f._type === WsMessageTypes.refreshDataModel),
        tap((msg: WsRefreshDataModelMessage) => {
          this.refreshRecordTypes(msg);
        }),
      )
      .subscribe();
  }

  readonly recordTypes$ = this.select(({ recordTypes }) => recordTypes);
  readonly recordTypesArray$ = this.select(({ recordTypes }) =>
    recordTypes?.values() === undefined ? undefined : Array.from(recordTypes?.values()),
  );
  readonly recordTypesLoaded$ = this.select(({ recordTypesLoaded }) => recordTypesLoaded);

  //#region Websocket state updates

  private fullRefresh(msg: WsRefreshDataModelMessage) {
    if (msg.type !== DataModelRefreshTypeEnum.full) {
      return;
    }
    const recTypes = new Map<string, DataModelRecordType>(
      msg.recordTypes.map((obj) => [obj.id.toUpperCase(), obj]),
    );
    const aliases = new Map<string, string>(
      Array.from(recTypes.values())
        .filter((f) => f.alias)
        .map((obj) => [obj.alias!.toUpperCase(), obj.id.toUpperCase()]),
    );
    this.updateState({ recordTypes: recTypes, recordTypeAliases: aliases });
  }

  private upsertRecordType(state: DataModelState, msg: WsRefreshDataModelMessage) {
    if (msg.type !== DataModelRefreshTypeEnum.upsert_record_type) {
      return;
    }
    console.log('upsertRecordType', msg.type, msg.recordTypes);
    const existingTypes = state.recordTypes ?? new Map<string, DataModelRecordType>();
    for (const msgType of msg.recordTypes) {
      existingTypes.set(msgType.id.toUpperCase(), msgType);
    }
    const aliases = new Map<string, string>(
      Array.from(existingTypes.values())
        .filter((f) => f.alias)
        .map((obj) => [obj.alias!.toUpperCase(), obj.id.toUpperCase()]),
    );
    this.updateState({ recordTypes: existingTypes, recordTypeAliases: aliases });
  }

  private removeRecordType(state: DataModelState, msg: WsRefreshDataModelMessage) {
    if (msg.type !== DataModelRefreshTypeEnum.remove_record_type) {
      return;
    }
    const existingTypes = state.recordTypes ?? new Map<string, DataModelRecordType>();
    for (const msgType of msg.recordTypes) {
      existingTypes.delete(msgType.id.toUpperCase());
    }
    const aliases = new Map<string, string>(
      Array.from(existingTypes.values())
        .filter((f) => f.alias)
        .map((obj) => [obj.alias!.toUpperCase(), obj.id.toUpperCase()]),
    );
    this.updateState({ recordTypes: existingTypes, recordTypeAliases: aliases });
  }

  private upsertField(state: DataModelState, msg: WsRefreshDataModelMessage) {
    const newState = { ...state };
    if (msg.type !== DataModelRefreshTypeEnum.upsert_field) {
      return;
    }
    if (!newState.recordTypes) {
      return;
    }
    for (const msgField of msg.fields) {
      if (!msgField.id || !msgField.recordTypeId) {
        continue;
      }
      if (!newState.recordTypes.get(msgField.recordTypeId.toUpperCase())) {
        continue;
      }
      if (newState.recordTypes.get(msgField.recordTypeId.toUpperCase())!.fields === undefined) {
        newState.recordTypes.get(msgField.recordTypeId.toUpperCase())!.fields = [];
      }
      const fieldMatch =
        newState.recordTypes
          .get(msgField.recordTypeId.toUpperCase())!
          .fields?.findIndex((f) => f.id?.toUpperCase() === msgField.id?.toUpperCase()) ?? -1;
      if (fieldMatch >= 0) {
        newState.recordTypes.get(msgField.recordTypeId.toUpperCase())!.fields![fieldMatch] =
          msgField;
      } else {
        newState.recordTypes.get(msgField.recordTypeId.toUpperCase())!.fields!.push(msgField);
      }
    }
    this.updateState({ recordTypes: newState.recordTypes });
  }

  private removeField(state: DataModelState, msg: WsRefreshDataModelMessage) {
    const newState = { ...state };
    if (msg.type !== DataModelRefreshTypeEnum.remove_field) {
      return;
    }

    if (!newState.recordTypes) {
      return;
    }
    for (const msgField of msg.fields) {
      if (!msgField.id || !msgField.recordTypeId) {
        continue;
      }
      if (!newState.recordTypes.get(msgField.recordTypeId.toUpperCase())) {
        continue;
      }
      if (newState.recordTypes.get(msgField.recordTypeId.toUpperCase())!.fields === undefined) {
        newState.recordTypes.get(msgField.recordTypeId.toUpperCase())!.fields = [];
      }
      const fieldMatch =
        newState.recordTypes
          .get(msgField.recordTypeId.toUpperCase())!
          .fields?.findIndex((f) => f.id?.toUpperCase() === msgField.id?.toUpperCase()) ?? -1;
      if (fieldMatch >= 0) {
        newState.recordTypes
          .get(msgField.recordTypeId.toUpperCase())!
          .fields!.splice(fieldMatch, 1);
      }
    }
    this.updateState({ recordTypes: newState.recordTypes });
  }

  readonly refreshRecordTypes = this.effect((trigger$: Observable<WsRefreshDataModelMessage>) =>
    trigger$.pipe(
      withLatestFrom(this.state$),
      tap(([msg, state]) => {
        // eslint-disable-next-line default-case
        switch (msg.type) {
          case DataModelRefreshTypeEnum.full:
            this.fullRefresh(msg);
            break;
          case DataModelRefreshTypeEnum.upsert_record_type:
            this.upsertRecordType(state, msg);
            break;
          case DataModelRefreshTypeEnum.remove_record_type:
            this.removeRecordType(state, msg);
            break;
          case DataModelRefreshTypeEnum.upsert_field:
            this.upsertField(state, msg);
            break;
          case DataModelRefreshTypeEnum.remove_field: {
            this.removeField(state, msg);
            break;
          }
        }
      }),
    ),
  );
  //#endregion

  loadRecordTypesAndFields() {
    return this.dataModelApiService.getTenantDataModel().pipe(
      catchError((error) => {
        console.error('unable to load record type fields', error);
        return of([]);
      }),
      map((recordTypes) => {
        this.initRecordTypes(recordTypes);
      }),
    );
  }

  initRecordTypes(recordTypes: DataModelRecordType[]) {
    this.patchState({
      recordTypes: new Map(recordTypes.map((obj) => [obj.id.toUpperCase(), obj])),
      recordTypeAliases: new Map<string, string>(
        Array.from(recordTypes.values())
          .filter((f) => f.alias)
          .map((obj) => [obj.alias!.toUpperCase(), obj.id.toUpperCase()]),
      ),
      recordTypesLoaded: true,
    });
  }

  readonly updateState = this.updater((state, updates: Partial<DataModelState>) => ({
    ...state,
    ...updates,
  }));

  recordTypesLoaded() {
    return this.get().recordTypesLoaded;
  }

  recordTypeExists(recordType: string) {
    return (
      this.get().recordTypes &&
      Array.from(this.get().recordTypes!.keys()).find(
        (f) => f.toLowerCase() === recordType.toLowerCase(),
      )
    );
  }

  isRecordType(key: string) {
    return this.getRecordType(key) !== undefined;
  }

  private getRecordTypeKey(typeKey: string) {
    if (!this.get().recordTypes) {
      return undefined;
    }
    const match = Array.from(this.get().recordTypes!.keys()).find(
      (f) => f.toLowerCase() === typeKey.toLowerCase(),
    );
    if (match) {
      return match;
    }

    const alias = Array.from(this.get().recordTypeAliases!.keys()).find(
      (f) => f.toLowerCase() === typeKey.toLowerCase(),
    );

    if (!!alias) {
      return this.get().recordTypeAliases?.get(alias);
    }

    return undefined;
  }

  getRecordType(typeKey: string): DataModelRecordType | undefined {
    const key = this.getRecordTypeKey(typeKey);

    if (!key) {
      return undefined;
    }
    return this.get().recordTypes?.get(key);
  }

  getRecordTypes() {
    return this.get().recordTypes;
  }

  getRecordTypeFieldsForCodeSet(codeSet: string) {
    const fields: DataModelRecordTypeField[] = [];
    if (!this.get().recordTypes) {
      return [];
    }
    for (const recType of this.get().recordTypes!.values()) {
      if (!recType.fields) {
        continue;
      }
      fields.push(...recType.fields.filter((f) => f.codeSet && f.codeSet === codeSet));
    }
    return fields;
  }

  getRecordTypeFieldsByRecordType(recordType: string) {
    const key = this.getRecordTypeKey(recordType);
    if (!key) {
      return undefined;
    }
    return this.get().recordTypes?.get(key)?.fields;
  }

  getFieldFromRecordTypeByFieldId(
    recordType: string,
    fieldId: string,
  ): DataModelRecordTypeField | undefined {
    const key = this.getRecordTypeKey(recordType);

    if (!key) {
      return undefined;
    }
    const fieldSet = this.get().recordTypes?.get(key)?.fields;

    if (!fieldSet) {
      return undefined;
    }

    return fieldSet.find(
      (f) => (f.id && fieldId && f.id.toUpperCase() === fieldId.toUpperCase()) || f.refName === fieldId,
    );
  }

  getCodesFromRecordTypePath(
    recordType: string,
    fieldPath: string,
    useCode: boolean
  ): Observable<{ id: string; value: string }[]> | undefined {
    const field = this.getFieldFromPath(recordType, fieldPath);
    if (!field || !field.codeSet) {
      return undefined;
    }
    return this.codesApi.getCodes(field.codeSet).pipe(
      untilDestroyed(this),
      map((codes) => {
        if (!codes || codes.length === 0) {
          return [];
        }
        return codes.map((m) => {
          return { id: useCode ? m.code : m.id!, value: m.description };
        });
      }),
      catchError((error) => {
        console.error('Unable to getCodesFromRecordTypePath', error);
        return of([]);
      }),
      shareReplay(1),
    );
  }

  parsePath(recordType: string, path: string) {
    const rootKey = this.getRecordTypeKey(recordType);
    if (!rootKey) {
      return undefined;
    }
    const parts = path.split('.');
    const pathParts: { recordType: string; fieldId: string; label: string }[] = [];
    let currRecType = rootKey;
    for (const part of parts) {
      const field = this.getFieldFromRecordTypeByFieldId(currRecType, part);
      if (!field) {
        continue;
      }
      pathParts.push({
        recordType: field.recordTypeId!,
        fieldId: field.id!,
        label: field.label ?? field.id!,
      });
      if (field.fieldType && this.getRecordTypeKey(field.fieldType)) {
        currRecType = this.getRecordTypeKey(field.fieldType)!;
      }
    }
    return pathParts;
  }

  getFieldFromPath(recordType: string, path: string) {
    const rootKey = this.getRecordTypeKey(recordType);
    if (!rootKey) {
      return undefined;
    }
    const parts = path?.split('.') ?? [];
    const fieldParts: DataModelRecordTypeField[] = [];
    let currRecType = rootKey;
    for (const part of parts) {
      const field = this.getFieldFromRecordTypeByFieldId(currRecType, part);
      if (field) {
        fieldParts.push(field);
        if (field.fieldType && this.getRecordTypeKey(field.fieldType)) {
          currRecType = this.getRecordTypeKey(field.fieldType)!;
        }
      }
    }
    if (fieldParts.length === 0) {
      return undefined;
    }
    return fieldParts.slice(-1)[0];
  }

  getLabelPartsFromPath(recordType: string, path: string) {
    const rootKey = this.getRecordTypeKey(recordType);
    if (!rootKey) {
      return undefined;
    }
    const parts = path.split('.');
    const labelParts: string[] = [];
    let currRecType = rootKey;
    for (const part of parts) {
      const field = this.getFieldFromRecordTypeByFieldId(currRecType, part);
      if (!field) {
        labelParts.push(part);
        continue;
      }
      labelParts.push(field.label ?? part);
      if (field.fieldType && this.getRecordTypeKey(field.fieldType)) {
        currRecType = this.getRecordTypeKey(field.fieldType)!;
      }
    }
    return labelParts;
  }

  getLabelFromPath(recordType: string, path: string) {
    return this.getLabelPartsFromPath(recordType, path)?.join(' > ');
  }

  getFieldsFromRecordType(
    recordTypeId: string,
    options?: {
      rootFieldsOnly?: boolean;
      includeListFields?: boolean;
      includeCoreFields?: boolean;
    },
    baseField?: DataModelRecordTypeField,
    depth = 1,
  ): DataModelRecordTypeField[] {
    const MAX_DEPTH = 4;
    if (!this.recordTypeExists(recordTypeId)) {
      return [];
    }
    const fieldsSource = this.getRecordTypeFieldsByRecordType(recordTypeId);
    if (!fieldsSource) {
      return [];
    }
    if (fieldsSource.length === 0) {
      return [];
    }

    const recTypeFields: DataModelRecordTypeField[] = [];

    for (const recField of fieldsSource.filter(
      (f) => options?.includeListFields || !(f.isList ?? false),
    )) {
      const useRef = baseField ? [baseField.refName, recField.refName].join('.') : recField.refName;
      const isRecordType = recField.fieldType && this.recordTypeExists(recField.fieldType);
      if (
        isRecordType &&
        !options?.rootFieldsOnly &&
        depth <= MAX_DEPTH &&
        !(recField.isList ?? false) &&
        !(
          baseField &&
          baseField.recordTypeId === recField.recordTypeId &&
          baseField.id?.toUpperCase() === recField.id?.toUpperCase()
        )
      ) {
        const passField = { ...recField };
        passField.refName = useRef!;
        passField.label = baseField ? `${baseField.label} > ${passField.label}` : passField.label;
        recTypeFields.push(
          ...this.getFieldsFromRecordType(recField.fieldType!, options, passField, depth + 1),
        );
      } else if (options?.includeCoreFields && isRecordType) {
        const fkField = { ...recField };
        fkField.refName = useRef!;
        recTypeFields.push(fkField);
      } else {
        if (!isRecordType) {
          recTypeFields.push({
            ...recField,
            label: baseField ? `${recField.label!} (${baseField.label})` : recField.label!,
            refName: useRef!,
          });
        }
      }
    }
    return recTypeFields;
  }
}
