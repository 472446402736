export function getRoleCategoryByValue(value) {
    return Object.entries(RoleCategories).find(([key, val]) => val === value)?.[0];
}
export var RoleCategories;
(function (RoleCategories) {
    RoleCategories["AccessGroups"] = "access-groups-category";
    RoleCategories["Accounting"] = "accounting";
    RoleCategories["AgencyManagement"] = "agency-management-category";
    RoleCategories["Alerts"] = "alerts-category";
    RoleCategories["AutopayPlans"] = "autopay-plans-category";
    RoleCategories["Carriers"] = "carriers";
    RoleCategories["Claims"] = "claims-category";
    RoleCategories["ClaimsAdministrators"] = "claims-administrators";
    RoleCategories["Codes"] = "codes-category";
    RoleCategories["Contacts"] = "contacts";
    RoleCategories["Dashboard"] = "dashboard-category";
    RoleCategories["DataModel"] = "data-model-category";
    RoleCategories["DocumentTemplates"] = "document-templates-category";
    RoleCategories["Employers"] = "employers";
    RoleCategories["FAQ"] = "faq-category";
    RoleCategories["FormsLibrary"] = "forms-library-category";
    RoleCategories["Incidents"] = "incidents";
    RoleCategories["Invoices"] = "invoices-category";
    RoleCategories["LedgerAccounts"] = "ledger-accounts-category";
    RoleCategories["LineItemTypes"] = "line-item-types-category";
    RoleCategories["Locations"] = "locations";
    RoleCategories["LossRuns"] = "loss-runs-category";
    RoleCategories["MarketingLibrary"] = "marketing-library-category";
    RoleCategories["Messages"] = "messages-category";
    RoleCategories["Notes"] = "notes-category";
    RoleCategories["Payroll"] = "payroll-category";
    RoleCategories["Policies"] = "policies-category";
    RoleCategories["PolicyAudits"] = "policy-audits-category";
    RoleCategories["Policyholders"] = "policyholders-category";
    RoleCategories["PolicyPayments"] = "policy-payments-category";
    RoleCategories["Quotes"] = "quotes-category";
    RoleCategories["Rating"] = "rating";
    RoleCategories["Rates"] = "rates";
    RoleCategories["Reinstatements"] = "reinstatements";
    RoleCategories["Renewals"] = "renewals-category";
    RoleCategories["Roles"] = "roles-category";
    RoleCategories["Submissions"] = "submissions-category";
    RoleCategories["SurplusCalculations"] = "surplus-dcalculations-category";
    RoleCategories["Tasks"] = "tasks-category";
    RoleCategories["TrailMaps"] = "trailmap-category";
    RoleCategories["Users"] = "users-category";
    RoleCategories["Views"] = "views-category";
    RoleCategories["Workflows"] = "workflows-category";
    RoleCategories["Commissions"] = "commissions-category";
    RoleCategories["Integrations"] = "integrations";
})(RoleCategories || (RoleCategories = {}));
export var GeneralPermissions;
(function (GeneralPermissions) {
    GeneralPermissions["Create"] = "create";
    GeneralPermissions["Edit"] = "edit";
    GeneralPermissions["Delete"] = "delete";
    GeneralPermissions["ExportData"] = "export-data";
    GeneralPermissions["CreateMessages"] = "create-messages";
    GeneralPermissions["DeleteMessages"] = "delete-messages";
    GeneralPermissions["ViewMessages"] = "messages";
    GeneralPermissions["CreateFiles"] = "create-files";
    GeneralPermissions["DeleteFiles"] = "delete-files";
    GeneralPermissions["ViewFiles"] = "files";
    GeneralPermissions["CreateNotes"] = "create-notes";
    GeneralPermissions["EditNotes"] = "edit-notes";
    GeneralPermissions["DeleteNotes"] = "delete-notes";
    GeneralPermissions["ViewNotes"] = "view-notes";
    GeneralPermissions["ViewDeletedNotes"] = "view-deleted-notes";
    GeneralPermissions["CreateTasks"] = "create-tasks";
    GeneralPermissions["EditTasks"] = "edit-tasks";
    GeneralPermissions["DeleteTasks"] = "delete-tasks";
    GeneralPermissions["ReassignTasks"] = "reassign-tasks";
    GeneralPermissions["ViewTasks"] = "view-tasks";
    GeneralPermissions["ViewOthers"] = "view-others";
    GeneralPermissions["EditOthers"] = "edit-others";
    GeneralPermissions["DeleteOthers"] = "delete-others";
    GeneralPermissions["ViewDeletedTasks"] = "view-deleted-tasks";
    GeneralPermissions["Administer"] = "admin";
})(GeneralPermissions || (GeneralPermissions = {}));
export var DashboardPermissions;
(function (DashboardPermissions) {
    DashboardPermissions["Share"] = "share-dashboard";
    DashboardPermissions["AgencyFilter"] = "agency-filter-dashboard";
})(DashboardPermissions || (DashboardPermissions = {}));
export var ViewsPermissions;
(function (ViewsPermissions) {
    ViewsPermissions["CreateSharedView"] = "create-shared-view";
    ViewsPermissions["EditOtherSharedViews"] = "edit-other-shared-views";
})(ViewsPermissions || (ViewsPermissions = {}));
export var SubmissionsPermissions;
(function (SubmissionsPermissions) {
    SubmissionsPermissions["CreateQuickQuote"] = "create-quick-quote";
    SubmissionsPermissions["CreateApplication"] = "create-application";
})(SubmissionsPermissions || (SubmissionsPermissions = {}));
export var ReinstatementsPermissions;
(function (ReinstatementsPermissions) {
    ReinstatementsPermissions["EditReinstatementRules"] = "edit-reinstatement-rules";
    ReinstatementsPermissions["AllowReinstatements"] = "allow-reinstatements";
})(ReinstatementsPermissions || (ReinstatementsPermissions = {}));
export var FormsPermissions;
(function (FormsPermissions) {
    FormsPermissions["UploadNew"] = "upload-new-form";
    FormsPermissions["Delete"] = "delete-form";
})(FormsPermissions || (FormsPermissions = {}));
export var MarketingLibraryPermissions;
(function (MarketingLibraryPermissions) {
    MarketingLibraryPermissions["UploadNew"] = "upload-new-marketing-form";
    MarketingLibraryPermissions["Delete"] = "delete-marketing-form";
})(MarketingLibraryPermissions || (MarketingLibraryPermissions = {}));
export var AgencyManagementPermissions;
(function (AgencyManagementPermissions) {
    AgencyManagementPermissions["UpdateSettings"] = "update-agency-settings";
})(AgencyManagementPermissions || (AgencyManagementPermissions = {}));
export var PolicyAuditPermissions;
(function (PolicyAuditPermissions) {
    PolicyAuditPermissions["MakePayment"] = "make-payment";
    PolicyAuditPermissions["Approve"] = "approve";
})(PolicyAuditPermissions || (PolicyAuditPermissions = {}));
export var PayrollPermissions;
(function (PayrollPermissions) {
    PayrollPermissions["AddNew"] = "add-new-payroll";
    PayrollPermissions["Update"] = "update-payroll";
    PayrollPermissions["MakePayment"] = "make-payroll-payment";
    PayrollPermissions["DeleteOpenWorksheet"] = "delete-open-worksheet";
    PayrollPermissions["DeleteSubmittedWorksheet"] = "delete-submitted-worksheet";
    PayrollPermissions["ModifySubmittedWorksheet"] = "modify-submitted-worksheet";
    PayrollPermissions["ModifyPaidWorksheet"] = "modify-paid-worksheet";
    PayrollPermissions["ConfigurePayrollSettings"] = "configure-payroll-settings";
    PayrollPermissions["ReraisePaidEvents"] = "reraise-paid-events";
    PayrollPermissions["SpecifySubmittedDate"] = "specify-submitted-date";
})(PayrollPermissions || (PayrollPermissions = {}));
export var PolicyPaymentsPermissions;
(function (PolicyPaymentsPermissions) {
    PolicyPaymentsPermissions["OneTimePayment"] = "make-one-time-payment";
    PolicyPaymentsPermissions["LogPayment"] = "log-payment";
    PolicyPaymentsPermissions["LogRefund"] = "log-refund";
    PolicyPaymentsPermissions["SavePaymentMethod"] = "save-payment-method";
    PolicyPaymentsPermissions["ReversePayment"] = "reverse-payment";
    PolicyPaymentsPermissions["ReallocatePayment"] = "reallocate-payment";
})(PolicyPaymentsPermissions || (PolicyPaymentsPermissions = {}));
export var AlertsPermissions;
(function (AlertsPermissions) {
    AlertsPermissions["Create"] = "create-alert";
    AlertsPermissions["Edit"] = "edit-alert";
    AlertsPermissions["Delete"] = "delete-alert";
})(AlertsPermissions || (AlertsPermissions = {}));
export var RatingPermissions;
(function (RatingPermissions) {
    RatingPermissions["CreateFormula"] = "create-rating-formula";
    RatingPermissions["EditFormula"] = "edit-rate";
    RatingPermissions["DeleteFormula"] = "delete-rate";
})(RatingPermissions || (RatingPermissions = {}));
export var RatePermissions;
(function (RatePermissions) {
    RatePermissions["CreateTable"] = "create-rate-table";
    RatePermissions["EditTable"] = "edit-rate-table";
    RatePermissions["DeleteTable"] = "delete-rate-table";
})(RatePermissions || (RatePermissions = {}));
export var UserPermissions;
(function (UserPermissions) {
    UserPermissions["CreateUser"] = "create-user";
    UserPermissions["EditUser"] = "edit-user";
    UserPermissions["DeleteUser"] = "delete-user";
})(UserPermissions || (UserPermissions = {}));
export var RolePermissions;
(function (RolePermissions) {
    RolePermissions["CreateRole"] = "create-role";
    RolePermissions["EditRole"] = "edit-role";
    RolePermissions["DeleteRole"] = "delete-role";
})(RolePermissions || (RolePermissions = {}));
export var AccessGroupPermissions;
(function (AccessGroupPermissions) {
    AccessGroupPermissions["CreateAccessGroup"] = "create-access-group";
    AccessGroupPermissions["EditAccessGroup"] = "edit-access-group";
    AccessGroupPermissions["DeleteAccessGroup"] = "delete-access-group";
})(AccessGroupPermissions || (AccessGroupPermissions = {}));
export var DataModelPermissions;
(function (DataModelPermissions) {
    DataModelPermissions["Create"] = "create-data-model";
    DataModelPermissions["Edit"] = "edit-data-model";
    DataModelPermissions["Delete"] = "delete-data-model";
})(DataModelPermissions || (DataModelPermissions = {}));
export var TrailMapPermissions;
(function (TrailMapPermissions) {
    TrailMapPermissions["Create"] = "create-trail-map";
    TrailMapPermissions["Edit"] = "edit-trail-map";
    TrailMapPermissions["Delete"] = "delete-trail-map";
})(TrailMapPermissions || (TrailMapPermissions = {}));
export var CodePermissions;
(function (CodePermissions) {
    CodePermissions["AddCodeSets"] = "add-code-sets";
    CodePermissions["EditCodeSets"] = "edit-code-sets";
    CodePermissions["DeleteCodeSets"] = "delete-code-sets";
})(CodePermissions || (CodePermissions = {}));
export var DocumentTemplatePermissions;
(function (DocumentTemplatePermissions) {
    DocumentTemplatePermissions["ManageDocumentTemplates"] = "manage-document-templates";
    DocumentTemplatePermissions["GenerateResults"] = "generate-document-template-results";
})(DocumentTemplatePermissions || (DocumentTemplatePermissions = {}));
export var WorkflowPermissions;
(function (WorkflowPermissions) {
    WorkflowPermissions["Create"] = "create-workflow";
    WorkflowPermissions["Edit"] = "edit-workflow";
    WorkflowPermissions["Delete"] = "delete-workflow";
    WorkflowPermissions["Run"] = "run-workflow";
})(WorkflowPermissions || (WorkflowPermissions = {}));
export var FAQPermissions;
(function (FAQPermissions) {
    FAQPermissions["Create"] = "create-faq";
    FAQPermissions["Edit"] = "edit-faq";
    FAQPermissions["Delete"] = "delete-faq";
})(FAQPermissions || (FAQPermissions = {}));
export var MessagePermissions;
(function (MessagePermissions) {
    MessagePermissions["Create"] = "create-message";
    MessagePermissions["View"] = "view-message";
})(MessagePermissions || (MessagePermissions = {}));
export var NotesPermissions;
(function (NotesPermissions) {
    NotesPermissions["CreateNotes"] = "create-notes";
    NotesPermissions["EditNotes"] = "edit-notes";
    NotesPermissions["DeleteNotes"] = "delete-notes";
    NotesPermissions["ViewNotes"] = "view-notes";
    NotesPermissions["ViewDeletedNotes"] = "view-deleted-notes";
})(NotesPermissions || (NotesPermissions = {}));
export var TasksPermissions;
(function (TasksPermissions) {
    TasksPermissions["CreateTasks"] = "create-tasks";
    TasksPermissions["EditTasks"] = "edit-tasks";
    TasksPermissions["DeleteTasks"] = "delete-tasks";
    TasksPermissions["ReassignTasks"] = "reassign-tasks";
    TasksPermissions["ViewTasks"] = "view-tasks";
    TasksPermissions["ViewDeletedTasks"] = "view-deleted-tasks";
})(TasksPermissions || (TasksPermissions = {}));
export var PolicyPermissions;
(function (PolicyPermissions) {
    PolicyPermissions["Admin"] = "admin-policy";
    PolicyPermissions["CreateEndorsement"] = "create-endorsement";
    PolicyPermissions["BindEndorsement"] = "bind-endorsement";
    PolicyPermissions["DeleteEndorsement"] = "delete-endorsement";
    PolicyPermissions["ExportLossRun"] = "export-loss-run";
    PolicyPermissions["CancelPolicy"] = "cancel-policy";
    PolicyPermissions["RescindPendingCancellation"] = "rescind-pending-cancellation";
})(PolicyPermissions || (PolicyPermissions = {}));
export var InvoicesPermissions;
(function (InvoicesPermissions) {
    InvoicesPermissions["AddNew"] = "add-new-invoice";
    InvoicesPermissions["Edit"] = "edit-invoice";
    InvoicesPermissions["Delete"] = "delete-invoice";
    InvoicesPermissions["ConfigureInvoice"] = "configure-invoice";
    InvoicesPermissions["Print"] = "print-invoice";
    InvoicesPermissions["AddInstallmentPlan"] = "add-installment-plan";
})(InvoicesPermissions || (InvoicesPermissions = {}));
export var LineItemTypesPermissions;
(function (LineItemTypesPermissions) {
    LineItemTypesPermissions["AddNew"] = "add-new-line-item-type";
    LineItemTypesPermissions["Edit"] = "edit-new-line-item-type";
    LineItemTypesPermissions["Delete"] = "delete-new-line-item-type";
})(LineItemTypesPermissions || (LineItemTypesPermissions = {}));
export var AutopayPlanPermissions;
(function (AutopayPlanPermissions) {
    AutopayPlanPermissions["AddNew"] = "add-new-autopay-plan";
    AutopayPlanPermissions["Edit"] = "edit-autopay-plan";
    AutopayPlanPermissions["Delete"] = "delete-autopay-plan";
})(AutopayPlanPermissions || (AutopayPlanPermissions = {}));
export var QuotePermissions;
(function (QuotePermissions) {
    QuotePermissions["Accept"] = "accept-quote";
    QuotePermissions["Approve"] = "approve-quote";
    QuotePermissions["Decline"] = "decline-quote";
    QuotePermissions["Deny"] = "deny-quote";
    QuotePermissions["Rules"] = "quote-rules";
    QuotePermissions["Print"] = "print-quote";
    QuotePermissions["Email"] = "email-quote";
    QuotePermissions["RequestInfo"] = "request-info-quote";
    QuotePermissions["Reassign"] = "reassign-quote";
    QuotePermissions["Edit"] = "edit-quote";
    QuotePermissions["Bind"] = "bind-quote";
})(QuotePermissions || (QuotePermissions = {}));
export var AccountingPermissions;
(function (AccountingPermissions) {
    AccountingPermissions["AddDeposit"] = "add-policyholder-deposit";
    AccountingPermissions["ViewDeposits"] = "view-deposits";
})(AccountingPermissions || (AccountingPermissions = {}));
export var LedgerAccountsPermissions;
(function (LedgerAccountsPermissions) {
    LedgerAccountsPermissions["AdminLedgerCodes"] = "admin-ledger-codes";
    LedgerAccountsPermissions["AddLedger"] = "add-policyholder-ledger";
})(LedgerAccountsPermissions || (LedgerAccountsPermissions = {}));
export var RenewalsPermissions;
(function (RenewalsPermissions) {
    RenewalsPermissions["GenerateQuote"] = "renewals-generate-quote";
    RenewalsPermissions["ManageRenewalRules"] = "manage-renewal-rules";
})(RenewalsPermissions || (RenewalsPermissions = {}));
export var ClaimPermissions;
(function (ClaimPermissions) {
    ClaimPermissions["ManageStatus"] = "manage-status";
    ClaimPermissions["ViewFinancials"] = "view-financials";
    ClaimPermissions["SetReserves"] = "set-reserves";
    ClaimPermissions["LogPayments"] = "log-payments";
    ClaimPermissions["EnterChecks"] = "check-writing";
})(ClaimPermissions || (ClaimPermissions = {}));
export var IncidentPermissions;
(function (IncidentPermissions) {
    IncidentPermissions["ManageStatus"] = "manage-status";
})(IncidentPermissions || (IncidentPermissions = {}));
export var PolicyholderPermissions;
(function (PolicyholderPermissions) {
    PolicyholderPermissions["Create"] = "policyholder-create";
    PolicyholderPermissions["Edit"] = "policyholder-edit";
})(PolicyholderPermissions || (PolicyholderPermissions = {}));
export var SurplusCalculationPermissions;
(function (SurplusCalculationPermissions) {
    SurplusCalculationPermissions["Create"] = "surplus-calculation-create";
    SurplusCalculationPermissions["Reclculate"] = "surplus-calculation-recalculate";
})(SurplusCalculationPermissions || (SurplusCalculationPermissions = {}));
export var CommissionsPermissions;
(function (CommissionsPermissions) {
    CommissionsPermissions["ManageSettings"] = "manage-commission-settings";
})(CommissionsPermissions || (CommissionsPermissions = {}));
export var IntegrationPermissions;
(function (IntegrationPermissions) {
    IntegrationPermissions["Create"] = "integration-create";
    IntegrationPermissions["Edit"] = "integration-edit";
})(IntegrationPermissions || (IntegrationPermissions = {}));
export const generalMessagePermissionMap = {
    [GeneralPermissions.CreateMessages]: 'Create Messages',
    [GeneralPermissions.DeleteMessages]: 'Delete Messages',
    [GeneralPermissions.ViewMessages]: 'View Messages',
};
export const generalFilePermissionMap = {
    [GeneralPermissions.CreateFiles]: 'Create Files',
    [GeneralPermissions.DeleteFiles]: 'Delete Files',
    [GeneralPermissions.ViewFiles]: 'View Files',
};
export const generalNotePermissionMap = {
    [GeneralPermissions.CreateNotes]: 'Create Notes',
    [GeneralPermissions.DeleteNotes]: 'Delete Notes',
    [GeneralPermissions.EditNotes]: 'Edit Notes',
    [GeneralPermissions.ViewNotes]: 'View Notes',
    //[GeneralPermissions.ViewDeletedNotes]: 'View Deleted Notes',
};
export const generalTaskPermissionMap = {
    [GeneralPermissions.CreateTasks]: 'Create Tasks',
    [GeneralPermissions.DeleteTasks]: 'Delete Tasks',
    [GeneralPermissions.EditTasks]: 'Edit Tasks',
    [GeneralPermissions.ReassignTasks]: 'Reassign Tasks',
    [GeneralPermissions.ViewTasks]: 'View Tasks',
    // [GeneralPermissions.ViewDeletedTasks]: 'View Deleted Tasks',
};
export const generalCrudPermissionMap = {
    [GeneralPermissions.Create]: 'Create',
    [GeneralPermissions.Edit]: 'Modify',
    [GeneralPermissions.Delete]: 'Delete',
    [GeneralPermissions.ExportData]: 'Export Data',
};
export const generalOthersRecordsPermissionMap = {
    [GeneralPermissions.ViewOthers]: `View Others' Records`,
    [GeneralPermissions.EditOthers]: `Edit Others' Records`,
    [GeneralPermissions.DeleteOthers]: `Delete Others' Records`
};
export function getGeneralRoleSet(permMap) {
    return Object.entries(permMap).map((map) => {
        return {
            id: map[0],
            readableName: map[1],
            enabled: false,
        };
    });
}
