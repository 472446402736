import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';

import { DataViewGridComponent } from './components/data-view-grid/data-view-grid.component';
import { DataViewsService } from './data-views.service';
import { DragulaModule } from 'ng2-dragula';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { SharedModule } from 'src/app/shared/shared.module';
import { IsLoadingModule } from '@service-work/is-loading';
import { TbDatatablesModule } from 'app/modules/tb-datatables/tb-datatables.module';
import { TableModule } from 'primeng/table';
import { DataViewerTableComponent } from './components/data-viewer-table/data-viewer-table.component';
import { DataReportTableComponent } from './components/data-report-table/data-report-table.component';
import { PaginatedDataViewerTableComponent } from './components/paginated-data-viewer-table/paginated-data-viewer-table.component';
import { DataModelModule } from 'app/modules/data-model/data-model.module';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  imports: [
    SharedModule,
    FuseAlertModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatCardModule,
    MatDatepickerModule,
    MatIconModule,
    MatCheckboxModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRadioModule,
    MatStepperModule,
    MatSidenavModule,
    MatDividerModule,
    MatRippleModule,
    MatTooltipModule,
    MatTabsModule,
    IsLoadingModule,
    TbDatatablesModule,
    QuillModule.forRoot(),
    DragulaModule,
    TableModule,
    DataModelModule,
    MatTableModule,
  ],
  declarations: [
    DataViewGridComponent,
    DataViewerTableComponent,
    DataReportTableComponent,
    PaginatedDataViewerTableComponent,
  ],
  providers: [DataViewsService],
  exports: [DataViewGridComponent, DataViewerTableComponent, PaginatedDataViewerTableComponent],
})
export class DataViewsModule {}
